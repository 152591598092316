<template>
  <div class="dashboard">
    <div class="container-fluid">
      <div class="content-area">
        <div class="dashboard-content">
          <div class="dashboard-header clearfix">
            <div class="row">
              <div class="col-md-6 col-sm-12"><h4>الرسائل</h4></div>
              <div class="col-md-6 col-sm-12">
                <div class="breadcrumb-nav">
                  <ul>
                    <li><router-link to="/my">الرئيسية</router-link></li>
                    <li class="active">الرسائل</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-2"></div>
            <div class="col-8">
              <div id="chat" class="rounded overflow-auto">
                <div id="messages-wrapper">
                  <div
                    v-for="(message, index) in chatObj.loadedMessages"
                    :key="index"
                    class="message"
                    :class="{ mine: userData.id == message.sender_id }"
                  >
                    <small class="d-block">{{ message.sender_name }} :</small>
                    <p>{{ message.text }}</p>
                    <small>{{ message.dateTime }}</small>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
              <textarea
                id="message-box"
                @keypress="sendMessage"
                rows="3"
                class="form-control mt-2"
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chat from "@/plugins/firebase";

export default {
  name: "my.messages",
  data() {
    return {
      chatObj: {
        partnerName: "",
        loadedMessages: [],
      },
    };
  },
  async activated() {
    if (
      typeof this.chatObj.initialized === "undefined" ||
      this.chatObj.initialized === false
    ) {
      await this.chat();
    }

    if (![typeof $, typeof this.scrollTo].includes("undefined")) {
      $("#chat").animate(
        {
          scrollTop: this.scrollTo,
        },
        0
      );
    }
    this.chatObj
      .unseenState(0, this.$store)
      .setUnseenCounter(false, this.$store);
  },
  deactivated() {
    this.chatObj
      .unseenState(0, this.$store)
      .setUnseenCounter(true, this.$store);
    this.saveScroll();
  },
  methods: {
    async chat() {
      await Object.assign(this.chatObj, await chat())
        .prepare(this.chatObj, null, this.$store)
        .unseenState(0, this.$store)
        .setUnseenCounter(false, this.$store);

      this.chatObj.loadedMessages = [];

      await this.chatObj.setMessagesEventListener((data) => {
        this.chatObj.loadedMessages.push(this.chatObj.setDateTime(data.val()));
      });

      this.chatObj.unseen = 0;
    },
    async sendMessage(e) {
      var keycode = event.keyCode ? event.keyCode : event.which;
      if (keycode == "13") {
        e.preventDefault();

        let message = $("#message-box").val();
        if (message.length > 0) {
          $("#message-box").val("");
          $("#message-box").focus();

          await this.chatObj.sendMessage(message);
        }
      }
    },
    saveScroll() {
      this.scrollTo = $("#chat").scrollTop();
    },
  },
  computed: {
    userData() {
      return { ...this.$store.state.userData };
    },
    unseen() {
      return this.$store.state.unseen;
    },
  },
  watch: {
    "chatObj.loadedMessages.length": {
      handler() {
        if (typeof $ !== "undefined") {
          $("#chat").animate({
            scrollTop: $("#messages-wrapper").height() + 2000,
          });
        }
      },
    },
  },
};
</script>

<style>
#chat {
  height: calc(75vh - 70px);
  background-color: #e5ddd5;
  overflow: auto;
}

.message {
  max-width: 60%;
  float: left;
  clear: both;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  text-align: right;
  margin: 5px 10px;
}

.mine {
  float: right;
  background-color: #dcf8c6;
}
</style>